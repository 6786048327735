.appBar {
    background: transparent !important;
    color: black;
    box-shadow: none;
    justify-content: center;
    padding-top: 10px;
    position: fixed;
    width: 100%;
    top: 0;
}

.signUp {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    background-color: #483698 !important;
    color: white !important;
    min-width: unset !important;
    padding: 10px 20px !important;
}

.navButton {
    color: black !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    text-decoration: none;
    text-transform: none;
    line-height: 23px !important;

}

.navButton:hover {
    background-color: transparent !important;
    color: #483698 !important;
}

.activeLink {
    color: #483698 !important;
    font-size: 18px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    text-decoration: none;
    text-transform: none;
    line-height: 23px !important;
    font-weight: bold !important;
}

.colorLogoContainer {
    display: none;
}

.whiteLogoContainer {
    display: flex;
}

@media only screen and (max-width: 960px) {
    .colorLogoContainer {
        display: flex;
    }

    .whiteLogoContainer {
        display: none;
    }
}