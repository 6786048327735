:root {
  --secondary-main: #4a3699;
  --common-white: #ffffff;
  --common-black: #000;
  --primary-main: #40c1ac;
  --error-main: #ff4a4a;
}

@font-face {
  font-family: "FavoritPro-Regular";
  src: url("../Fonts/Inter/static/Inter-Regular.ttf");
}

@font-face {
  font-family: "FavoritPro-Medium";
  src: url("../Fonts/Inter/static/Inter-Medium.ttf");
}

.floatingLabelFocusStyle {
  color: var(--secondary-main) !important;
}

.errorText {
  color: var(--error-main) !important;
  font-size: 14px !important;
  margin-top: 3px !important;
}

.assignLabelText {
  color: var(--secondary-main) !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 600px) {
  .assignLabelText {
    margin-bottom: 6px !important;
  }
}

.moduleChip {
  margin: 0 4px 6px 4px !important;
  padding: 10px 0;
  font-weight: 400;
  min-height: 40px;
  color: var(--common-white) !important;
  background-color: var(--primary-main) !important;
  border-color: var(--primary-main) !important;
}

@media only screen and (max-width: 960px) {
  .moduleChip {
    margin-top: 8px !important;
  }
}

.cancelButton {
  justify-content: flex-end !important;
  background-color: transparent !important;
  color: var(--common-black) !important;
}

.buttonHoverNone:hover {
  background-color: var(--common-white) !important;
  box-shadow: none !important;
}

.MuiOutlinedInput-notchedOutline .Mui-focused {
  border-color: var(--secondary-main) !important;
}

.MuiSelect-select {
  padding: 10px !important;
}

.cssOutlinedInput {
  border-radius: 10px !important;
  background-color: var(--common-white) !important;
}

.input {
  color: var(--secondary-main) !important;
  padding: 10px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.inputWithoutPadding {
  padding: 10px 10px 10px 0px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.Mui-error {
  font-size: 14px !important;
  margin-left: 0 !important;
}

.searchable-Select input {
  padding: 0 !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fw-regular {
  font-family: "FavoritPro-Regular" !important;
  font-style: normal;
}

.fw-medium {
  font-family: "FavoritPro-Medium" !important;
  font-style: normal !important;
}

.w-100 {
  width: 100% !important;
}

.d-flex-all-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-flex-start {
  align-items: flex-start;
}

.align-self-center {
  align-self: center;
}

.textAlignCenter {
  text-align: center;
}

.text-align-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.flex-col {
  flex-direction: column !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-26 {
  font-size: 26px !important;
}

.lh-36 {
  line-height: 36px !important;
}

.lh-29 {
  line-height: 29px !important;
}

.lh-24 {
  line-height: 24px !important;
}

.lh-25 {
  line-height: 25px !important;
}

.lh-27 {
  line-height: 27px !important;
}

.lh-21 {
  line-height: 21px !important;
}

.lh-19 {
  line-height: 19px !important;
}

.lh-18 {
  line-height: 18px !important;
}

.lh-16 {
  line-height: 16px !important;
}

.lh-14 {
  line-height: 14px !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-400 {
  font-weight: 400 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width: 650px) {
  .f-24 {
    font-size: 19px !important;
  }
}

@media (max-width: 500px) {
  .f-24 {
    font-size: 17px !important;
  }
}

.msa-order ol {
  counter-reset: item
}

.msa-order li {
  display: block
}

.msa-order li:before {
  content: counters(item, ".") ". ";
  counter-increment: item
}

.msa-order ol ol ol li:before {
  content: counter(item, upper-alpha) ". "
}



.textFieldEndIcon {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px !important;
  font-family: "FavoritPro-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 5px 0px 5px 16px !important;
  color: #666666
}

.textFieldStartEndIcon {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px !important;
  font-family: "FavoritPro-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 5px 0px 5px 12px !important;
  color: #666666
}

.textFieldStartEndIconNumbers {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 26px;
  max-height: 250px;
  overflow-y: auto !important;
  font-family: "FavoritPro-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 5px 0px 5px 12px !important;
  color: #333333
}

.textFieldMultilineClass {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px !important;
  font-family: "FavoritPro-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 5px 0px 5px 12px !important;
  color: #333333
}



.textFieldEndIconPadding {
  padding-right: 0px !important;
}

.textFieldStartIconPadding {
  padding-left: 0px !important;
}

.textFieldBorderColor {
  border-color: #D6D6D6 !important;
  padding-right: 0px !important;

}

.commonDropDown {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px !important;
  font-family: "FavoritPro-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.4px;
}

.border-radius-10 {
  border-radius: 10 !important;
}

.labelColor {
  color: #333333 !important
}