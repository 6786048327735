body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Source Sans Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import url('./assets/styles/authHeader.css');
@import url('./assets/styles/commonStyle.css');
@import url('./assets//styles/pagination.css');
@import url('react-toastify/dist/ReactToastify.css');

/* width */
::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: 'inset 0 0 6px #40C1AC';
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4a3699;
  outline: '1px solid #4A3699';
  border-radius: 5px;
}
.scrollable-container {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
}

/* Global Classes Start */
button {
  border-radius: 10px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-disabled {
  cursor: not-allowed;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-30 {
  border-radius: 30px !important;
}

.d-flex-all-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.d-flex {
  display: flex !important;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: end;
}

.align-self-center {
  align-self: center;
}

.text-align-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.w-100 {
  width: 100% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.h-100 {
  height: 100% !important;
}

.h-100-vh {
  height: 100vh;
}

.mr-0 {
  margin-right: 0 !important;
}

.pr-5 {
  padding-right: 5px;
}

input {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 10px !important;
  color: #000000;
}

.danger {
  border-width: 1px;
  border-color: red;
}

.border-none {
  border: none !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.text-capitalize {
  text-transform: capitalize;
}

/* Global Classes End */

/* MUI Table */
.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}

.dragDropDisabled .is-disabled {
  border: none !important;
  cursor: pointer !important;
  min-width: 100%;
  min-height: fit-content;
}

@media only screen and (max-height: 600px) {
  .leftSection {
    max-width: 43.66% !important;
  }
}

@media only screen and (max-height: 600px) {
  .leftSectionReset {
    max-width: 40.66% !important;
  }
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4a3699 !important;
  border-width: 2px;
}

.datePickerCustom .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #FF4A4A !important;
  border-width: 2px;
}

.toaster-container {
  background-color: #4caf50;
  color: white;
  min-height: 48px;
  max-height: 800px;
  padding: 6px 16px;
  border-radius: 8px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 14px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1rem;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  direction: ltr;
}

.toaster-container-error {
  background-color: #e74c3c;
  color: white;
  min-height: 48px;
  max-height: 800px;
  padding: 6px 16px;
  border-radius: 8px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 14px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1rem;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  direction: ltr;
}

.Toastify__close-button {
  align-self: center;
  color: white;
  opacity: 1
}